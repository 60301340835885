var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseModal',{ref:"modal",attrs:{"title":"Nieuw project","max-width":"tw-max-w-5xl"}},[_c('FormulateForm',{attrs:{"name":"projectCreateForm","invalid-message":"Gelieve de verplichte velden correct in te vullen."},on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('div',{staticClass:"tw-mb-8"},[_c('h2',[_vm._v("Algemene informatie")]),_c('div',{staticClass:"tw-my-4 tw-grid md:tw-grid-cols-3 tw-gap-4"},[_c('div',{staticClass:"tw-col-span-3 tw-grid md:tw-grid-cols-2 tw-gap-4"},[_c('FormulateInput',{attrs:{"type":"text","name":"name","label":"Naam","placeholder":"Naam","validation":"required:trim","outer-class":"tw-m-0"}}),_c('FormulateInput',{attrs:{"type":"text","name":"reference","label":"Referentie","placeholder":"Referentie","validation":"bail|required:trim|validateProjectReference","validation-rules":{ validateProjectReference: _vm.validateProjectReference },"validation-messages":{
              validateProjectReference: 'Er bestaat al een project met deze referentie'
            },"outer-class":"tw-m-0"}}),_c('FormulateInput',{attrs:{"type":"select","name":"niche","label":"Niche","options":_vm.NICHES,"validation":"required","outer-class":"tw-m-0"}})],1),_c('FormulateInput',{attrs:{"type":"autocomplete","auto-complete-type":"office","multiple":true,"name":"offices","label":"Kantoor","placeholder":"Selecteer kantoren","validation":"required","outer-class":"tw-m-0"}}),_c('FormulateInput',{attrs:{"type":"autocomplete","auto-complete-type":"collaborator","multiple":true,"name":"collaborators","label":"Makelaar","placeholder":"Selecteer makelaars","validation":"required","outer-class":"tw-m-0"}}),_c('FormulateInput',{attrs:{"type":"autocomplete","auto-complete-type":"contact","name":"owner","label":"Eigenaar","placeholder":"Selecteer eigenaar","validation":"required","outer-class":"tw-m-0"},scopedSlots:_vm._u([{key:"label",fn:function(ref){
            var label = ref.label;
            var id = ref.id;
            var classes = ref.classes;
return [_c('div',{staticClass:"tw-flex tw-justify-between"},[_c('label',{staticClass:"formulate-label",class:classes.label,attrs:{"for":id},domProps:{"textContent":_vm._s(label)}}),_c('button',{staticClass:"link tw-font-semibold",attrs:{"type":"button"},on:{"click":_vm.showContactCreateModal}},[_c('i',{staticClass:"far fa-user-plus"}),_vm._v(" Maak nieuw ")])])]}}],null,true)})],1)]),_c('div',{staticClass:"tw-my-8"},[_c('h2',[_vm._v("Projectinformatie")]),_c('div',{staticClass:"tw-my-4 tw-grid md:tw-grid-cols-3 tw-gap-4"},[_c('FormulateInput',{attrs:{"type":"autocomplete","auto-complete-type":"street","name":"street","label":"Straat","placeholder":"Zoek een straat","validation":"required","outer-class":"tw-m-0"},on:{"callback":_vm.streetSelected}}),_c('div',{staticClass:"tw-grid md:tw-grid-cols-2 tw-gap-4"},[_c('FormulateInput',{attrs:{"type":"text","name":"number","label":"Huisnummer","placeholder":"Huisnummer","validation":"required:trim","outer-class":"tw-m-0"}}),_c('FormulateInput',{attrs:{"type":"text","name":"box","label":"Bus","placeholder":"Bus","outer-class":"tw-m-0"}})],1),_c('FormulateInput',{attrs:{"type":"autocomplete","auto-complete-type":"city","name":"city","label":"Plaats","placeholder":"Zoek op postcode of plaatsnaam","validation":"required","outer-class":"tw-m-0"}}),_c('FormulateInput',{attrs:{"type":"textarea","name":"notes","label":"Notities","placeholder":"Notities","input-class":['tw-h-16'],"outer-class":"tw-m-0 tw-col-span-3"}})],1)]),_c('div',{staticClass:"tw-flex tw-flex-col tw-items-end tw-w-full"},[_c('FormulateErrors'),_c('FormulateInput',{attrs:{"type":"submit","title":"Project aanmaken","disabled":isLoading}},[_c('i',{class:[
            'fas tw-mr-1',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-check'
          ]}),_vm._v(" Project aanmaken ")])],1)]}}]),model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}}),_c('ContactCreateModal',{ref:"contactCreateModal",attrs:{"redirect":false},on:{"success":_vm.newOwnerCreated}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }