<template>
  <EntityHoverable :value="object"></EntityHoverable>
</template>

<script>
import EntityHoverable from '../EntityHoverable'

export default {
  name: 'ReferenceHoverable',
  props: ['value', 'object'],
  components: {
    EntityHoverable
  }
}
</script>
