<template>
  <div>
    <PageHeader title="Project zoeken">
      <template #buttons>
        <button
          type="button"
          title="Nieuw project aanmaken"
          class="action tw-px-2 tw-bg-success tw-font-normal tw-text-sm"
          @click="showNewProjectWizard"
        >
          <i class="far fa-plus" /> Project aanmaken
        </button>
      </template>
    </PageHeader>

    <div class="tw-px-2.5 tw-pt-5 tw-pb-16 tw-mx-auto">
      <div class="tw-mb-8 tw-py-4 tw-px-6 tw-bg-white tw-rounded tw-shadow-lg">
        <FormulateForm
          :key="searchKey"
          v-model="filters"
          name="projectSearch"
          debounce
          class="tw-my-4"
        >
          <div class="tw-mb-6 tw-grid md:tw-grid-cols-2 tw-gap-x-6 tw-gap-y-4">
            <FormulateInput
              type="text"
              name="reference"
              label="Referentie"
              placeholder="Referentie"
              outer-class="tw-my-0"
            />
            <FormulateInput
              type="text"
              name="name"
              label="Naam"
              placeholder="Naam"
              outer-class="tw-my-0"
            />
            <FormulateInput
              type="text"
              name="street"
              label="Straat"
              placeholder="Straat"
              outer-class="tw-my-0"
            />
            <div class="tw-grid md:tw-grid-cols-2 tw-gap-4">
              <FormulateInput
                type="text"
                name="number"
                label="Huisnummer"
                placeholder="Huisnummer"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="text"
                name="box"
                label="Bus"
                placeholder="Bus"
                outer-class="tw-m-0"
              />
            </div>
            <FormulateInput
              type="autocomplete"
              auto-complete-type="city"
              :multiple="true"
              name="cities"
              label="Plaats"
              placeholder="Zoek op postcode, plaatsnaam of provincie"
              outer-class="tw-my-0"
            />
            <FormulateInput
              type="checkbox"
              name="include_archived"
              label="Gearchiveerde projecten"
              outer-class="tw-my-0"
            />
          </div>

          <div class="tw-grid sm:tw-grid-flow-col sm:tw-max-w-sm tw-gap-x-6 tw-gap-y-4 tw-text-base tw-text-center">
            <router-link
              :to="{ query: { filters: JSON.stringify(filters) } }"
              class="tw-px-6 tw-py-2 !tw-text-white !tw-no-underline tw-bg-success hover:tw-bg-darken-success tw-rounded-md"
            >
              <i class="tw-mr-2 far fa-search" /> Zoeken
            </router-link>
            <button
              type="button"
              class="tw-px-6 tw-py-2 tw-bg-danger hover:tw-bg-darken-danger tw-rounded-md tw-text-white"
              @click="clearFilters"
            >
              <i class="tw-mr-2 far fa-times" /> Filters wissen
            </button>
          </div>
        </FormulateForm>
      </div>

      <DataTable
        v-show="showDataTable"
        v-model="selectedRecords"
        :loading="loading"
        :headers="headers"
        :can-select="true"
        :select-record-object="true"
        v-bind="projects"
        @changePage="loadProjects"
      >
        <template #toolbar>
          <div class="tw-mb-2 tw-flex tw-justify-end">
            <button
              type="button"
              title="Pubactie registreren"
              :disabled="!selectedRecords.length"
              class="action tw-bg-success"
              @click="openPubActionModal"
            >
              <i class="far fa-bullhorn" /> Pubactie registreren
            </button>
          </div>
        </template>
        <template #item-address="{ item }">
          {{ item.street }} {{ item.number }} {{ item.box }}
        </template>
        <template #item-city="{ item }">
          <span v-if="item.city">{{ item.city.zip_code }} {{ item.city.name }}</span>
        </template>
      </DataTable>
    </div>

    <ProjectCreateModal ref="projectCreateModal" />
    <PublicationActionCreateModal ref="pubActionModal" :projects="selectedRecords" />
  </div>
</template>

<script>
import merge from 'lodash/merge'

import DataTable from '@/components/DataTable.vue'
import PageHeader from '@/components/PageHeader.vue'

import ProjectCreateModal from '@/components/properties/ProjectCreateModal'
import ReferenceHoverable from '@/components/iam/table_components/ReferenceHoverable'
import PublicationActionCreateModal from '@/components/properties/PublicationActionCreateModal'

import { getProjects } from '@/services/projects'
import { successModal, errorModal } from '@/modalMessages'

export default {
  name: 'ProjectSearch',
  components: {
    DataTable,
    PageHeader,
    ProjectCreateModal,
    PublicationActionCreateModal
  },
  data () {
    return {
      searchKey: 'init',
      filters: {},
      loading: false,
      showDataTable: false,
      // The object projects includes keys to all the props that are needed in the DataTable, hence, we can use v-bind directly for clean code
      projects: {
        count: null,
        next: null,
        previous: null,
        results: []
      },
      selectedRecords: []
    }
  },
  computed: {
    headers () {
      return [
        { value: 'reference', text: 'Project', renderComponent: ReferenceHoverable },
        { value: 'name', text: 'Naam' },
        { value: 'address', text: 'Adres' },
        { value: 'city', text: 'Plaats' }
      ]
    }
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler (value) {
        if (!value.filters) {
          this.showDataTable = false
          return false
        }

        this.showDataTable = true
        const parsedValues = JSON.parse(value.filters)
        const mergedFilters = merge(this.filters, parsedValues)
        this.filters = Object.assign({}, this.filters, mergedFilters)
        this.queryProjects()
      }
    }
  },
  methods: {
    resetSelections () {
      this.selectedRecords = []
    },
    showNewProjectWizard () {
      this.$refs.projectCreateModal.show()
    },
    openPubActionModal () {
      this.$refs.pubActionModal.show()
    },
    async loadProjects (payload) {
      try {
        this.loading = true
        const response = await getProjects(payload)
        this.projects = response.data
        this.loading = false
        return response
      } catch (error) {
        console.error(error)
        errorModal('Kan projecten niet laden, probeer het opnieuw.')
      }
    },
    async queryProjects (data = this.filters) {
      const params = { ...data }
      if (params.cities?.length) params.cities = params.cities.map(city => city.id)
      const response = await this.loadProjects({ params })
      this.resetSelections()
      return response
    },
    async clearFilters () {
      this.searchKey = Math.random() // Reset form and it's values
      this.filters = {} // set filters to default value
      this.resetSelections()
      this.$router.push({ query: {} }).catch(() => {})

      successModal('Alle filters gewist.')
    }
  }
}
</script>
