<template>
  <BaseModal ref="modal" title="Nieuw project" max-width="tw-max-w-5xl">
    <FormulateForm
      #default="{ isLoading }"
      v-model="values"
      name="projectCreateForm"
      invalid-message="Gelieve de verplichte velden correct in te vullen."
      @submit="submit"
    >
      <div class="tw-mb-8">
        <h2>Algemene informatie</h2>
        <div class="tw-my-4 tw-grid md:tw-grid-cols-3 tw-gap-4">
          <div class="tw-col-span-3 tw-grid md:tw-grid-cols-2 tw-gap-4">
            <FormulateInput
              type="text"
              name="name"
              label="Naam"
              placeholder="Naam"
              validation="required:trim"
              outer-class="tw-m-0"
            />
            <FormulateInput
              type="text"
              name="reference"
              label="Referentie"
              placeholder="Referentie"
              validation="bail|required:trim|validateProjectReference"
              :validation-rules="{ validateProjectReference }"
              :validation-messages="{
                validateProjectReference: 'Er bestaat al een project met deze referentie'
              }"
              outer-class="tw-m-0"
            />

            <FormulateInput
              type="select"
              name="niche"
              label="Niche"
              :options="NICHES"
              validation="required"
              outer-class="tw-m-0"
            />
          </div>

          <FormulateInput
            type="autocomplete"
            auto-complete-type="office"
            :multiple="true"
            name="offices"
            label="Kantoor"
            placeholder="Selecteer kantoren"
            validation="required"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="autocomplete"
            auto-complete-type="collaborator"
            :multiple="true"
            name="collaborators"
            label="Makelaar"
            placeholder="Selecteer makelaars"
            validation="required"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="autocomplete"
            auto-complete-type="contact"
            name="owner"
            label="Eigenaar"
            placeholder="Selecteer eigenaar"
            validation="required"
            outer-class="tw-m-0"
          >
            <template #label="{ label, id, classes }">
              <div class="tw-flex tw-justify-between">
                <label :for="id" :class="classes.label" v-text="label" class="formulate-label" />
                <button
                  type="button"
                  class="link tw-font-semibold"
                  @click="showContactCreateModal"
                >
                  <i class="far fa-user-plus" /> Maak nieuw
                </button>
              </div>
            </template>
          </FormulateInput>
        </div>
      </div>

      <div class="tw-my-8">
        <h2>Projectinformatie</h2>
        <div class="tw-my-4 tw-grid md:tw-grid-cols-3 tw-gap-4">
          <FormulateInput
            type="autocomplete"
            auto-complete-type="street"
            name="street"
            label="Straat"
            placeholder="Zoek een straat"
            validation="required"
            outer-class="tw-m-0"
            @callback="streetSelected"
          />

          <div class="tw-grid md:tw-grid-cols-2 tw-gap-4">
            <FormulateInput
              type="text"
              name="number"
              label="Huisnummer"
              placeholder="Huisnummer"
              validation="required:trim"
              outer-class="tw-m-0"
            />
            <FormulateInput
              type="text"
              name="box"
              label="Bus"
              placeholder="Bus"
              outer-class="tw-m-0"
            />
          </div>

          <FormulateInput
            type="autocomplete"
            auto-complete-type="city"
            name="city"
            label="Plaats"
            placeholder="Zoek op postcode of plaatsnaam"
            validation="required"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="textarea"
            name="notes"
            label="Notities"
            placeholder="Notities"
            :input-class="['tw-h-16']"
            outer-class="tw-m-0 tw-col-span-3"
          />
        </div>
      </div>

      <div class="tw-flex tw-flex-col tw-items-end tw-w-full">
        <FormulateErrors />
        <FormulateInput
          type="submit"
          title="Project aanmaken"
          :disabled="isLoading"
        >
          <i
            :class="[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-check'
            ]"
          />
          Project aanmaken
        </FormulateInput>
      </div>
    </FormulateForm>

    <ContactCreateModal
      ref="contactCreateModal"
      :redirect="false"
      @success="newOwnerCreated"
    />
  </BaseModal>
</template>

<script>
import ContactCreateModal from '@/components/contacts/ContactCreateModal'

import { NICHES, REGIONS } from '@/forms/selectOptions'
import { createProject, validateProjectReference } from '@/services/projects'

import { mapGetters } from 'vuex'

export default {
  name: 'ProjectCreateModal',
  components: { ContactCreateModal },
  props: {
    project: {
      type: Object,
      default: null
    },
    redirect: {
      // Takes user to the Property's page after creation.
      type: Boolean,
      default: true
    }
  },
  constants: {
    NICHES,
    REGIONS
  },
  data () {
    return {
      values: {}
    }
  },
  computed: {
    ...mapGetters('account', ['collaborator'])
  },
  methods: {
    show () {
      this.values = {}
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    showContactCreateModal () {
      this.$refs.contactCreateModal.show()
    },
    streetSelected (street) {
      if (street?.city) this.values.city = street.city
      if (street?.number) this.values.number = street.number
    },
    newOwnerCreated (contact) {
      const { type, company_name, first_name, last_name } = contact
      const display_name = type === 'B' ? company_name : `${first_name} ${last_name}`
      this.$set(this.values, 'owner', { display_name, ...contact })
      return contact
    },
    async validateProjectReference ({ value }) {
      const response = await validateProjectReference(value)
      return response.data?.valid_reference
    },
    async submit (data) {
      try {
        const payload = { ...data }
        payload.city = payload.city?.id
        payload.owner = payload.owner?.id
        payload.street = payload.street?.text
        payload.offices = payload.offices.map(collaborator => collaborator.id)
        payload.collaborators = payload.collaborators.map(collaborator => collaborator.id)

        const response = await createProject(payload)
        const project = response.data
        if (this.redirect) this.$router.push({ name: 'ProjectDetails', params: { id: response.data.id } })
        this.$emit('success', project)
        this.hide()
        return response
      } catch (error) {
        console.error(error)
        this.$formulate.handle(error, 'projectCreateForm')
      }
      return data
    }
  }
}
</script>

<style scoped>
h2 {
  @apply !tw-m-0;
}
</style>
